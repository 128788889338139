import React from "react";
const AboutUs = () => {
  return (
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-4 text-5">About Me</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Know Me More
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
            <h2 className="text-8 fw-400 mb-3">
              Hi, I'm{" "}
              <span className="fw-700 border-bottom border-3 border-primary">
                Robert Więckowski
              </span>
            </h2>
            <p className="text-5">
              I'm an ambitious front-end developer with a passion for coding,
              web design and photography. I enjoy developing simple and clean
              websites that provide real value to the users. Some software
              houses and clients have achieved satisfactory results while
              working with me.
              {/* Delivering work within time a and of high quality which meets
              supervisors and client’s requirements is my moto. */}
            </p>
            <p className="text-5">
              “My ego is not tied to my “perfect” or “imperfect” work product.
              My ego is only tied to my attempts to do the best job I know how,
              and to learn from my mistakes, not the initial result of my work”.
            </p>
            <small> &mdash; Gerald M. Weinberg</small>
          </div>
          {/* <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="featured-box style-4">
              <div className="featured-box-icon text-20 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat" data-wow-delay="1.3s">
                  2
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                Years of <span className="fw-700">Experiance</span>
              </h3>
            </div>
          </div> */}
          <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="featured-box style-4 ">
              <div
                style={{ backgroundColor: "#fcfcfc" }}
                className="featured-box-icon text-20 fw-700 "
              >
                <span
                  style={{ color: "#D69F83" }}
                  className="wow heartBeat"
                  data-wow-delay="1.3s"
                >
                  3
                </span>
                <span style={{ fontSize: "2.2rem", marginLeft: ".2rem" }}>
                  Years
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                of <span className="fw-700">Experiance</span>
              </h3>
            </div>
          </div>
        </div>
        <div className="row gy-3 mt-4">
          {/* <div className="col-6 col-lg-3 wow fadeInUp">
            <p className="text-muted fw-500 mb-0">Name:</p>
            <p className="text-4 text-dark fw-600 mb-0">Robert Więckowski</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
            <p className="text-muted fw-500 mb-0">Email:</p>
            <p className="text-4 fw-600 mb-0">
              <a className="link-dark" href="mailto:message4robertw@gmail.com">
                mail4robertw@gmail.com
              </a>
            </p>
          </div> */}
          {/* <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
            <p className="text-muted fw-500 mb-0">Date of birth:</p>
            <p className="text-4 text-dark fw-600 mb-0">26 October</p>
          </div> */}
          {/* <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
            <p className="text-muted fw-500 mb-0">From:</p>
            <p className="text-4 text-dark fw-600 mb-0">Warsaw, Poland</p>
          </div> */}
          <div className="col-12 col-lg-8 wow fadeInUp" data-wow-delay="0.4s">
            <p className="text-muted fw-500 mb-0">Tech stack: </p>
            <p className="text-4 text-dark fw-600 mb-0">
              JavaSctipt, TypeScript, React, Rect Native, Next, .NET, Blazor C#,
              Bootstrap, Core UI, Tailwind, NativeBase, SCSS, CSS, HTML5
            </p>
            <p className="text-4 text-dark fw-400 mb-0">
              {" "}
              Hooks, Redux, Redux Toolkit, Redux Saga, Redux Thunk, WebSocket,
              JWT, CRUD, Formik, GIT
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
