const Hoop = () => {
  return (
    <section className="wrapper">
     <div className="hoop-wrapper">
      <div id="hoop" className="hoop">
        <div className="carousel one"></div>
        <div className="carousel two"><span className='carousel-letter'>c</span></div>
        <div className="carousel three"><span className='carousel-letter'>o</span></div>
        <div className="carousel four"><span className='carousel-letter'>d</span></div>
        <div className="carousel five"><span className='carousel-letter'>e</span></div>
        <div className="carousel six"></div>
        <div className="carousel seven"></div>
        <div className="carousel eight"><span className='carousel-letter'>d</span></div>
        <div className="carousel nine"><span className='carousel-letter'>o</span></div>
        <div className="carousel ten"><span className='carousel-letter'>p</span></div>
        <div className="carousel eleven"><span className='carousel-letter'>e</span></div>
        <div className="carousel twelve"></div>
       </div>
      </div>
    </section>
  )
}

export default Hoop