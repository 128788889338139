import React from "react";
import { Link } from "react-scroll";
import Typewriter from "typewriter-effect";
import { scrollDuration } from "../../../config/commonConfig";

const FullScreenDefaultIntro = () => {
  return (
    <section
      id="home"
      className="bg-primary d-flex fullscreen position-relative py-5"
    >
      <div className="container my-auto py-4">
        <div className="row">
          <div className="col-lg-7 text-center text-lg-start align-self-center order-1 order-lg-0 wow fadeIn">
            <h1 className="text-12 fw-300 mb-0">
              Hi, I'm{" "}
              <span className="underlined underline-mask text-12 fw-300 mb-0">
                Robert
              </span>
            </h1>
            <h2 className="text-20 fw-600 text-uppercase mb-0 ms-n1 characters-underline">
              <Typewriter
                options={{
                  strings: ["Front-End", "Developer"],
                  autoStart: true,
                  loop: true,
                  delay: 175,
                }}
              />
            </h2>
            <p className="text-5">based in Warsaw, Poland.</p>
            <Link
              className="btn btn-dark rounded-0 smooth-scroll mt-3 py-3 btn-shine"
              smooth="easeInOutQuint"
              duration={scrollDuration}
              style={{ cursor: "pointer" }}
              to="portfolio"
            >
              Check Out Projects
            </Link>
            <Link
              className="btn btn-link text-dark smooth-scroll mt-3 link-intro"
              smooth="easeInOutQuint"
              duration={scrollDuration}
              style={{ cursor: "pointer" }}
              to="contact"
            >
              <span className="bi">L</span>
              <span className="bi">e</span>
              <span className="bi">t</span>
              <span className="bi">'</span>
              <span className="bi">s</span>
              <span className="bi">&nbsp;</span>
              <span className="bi">t</span>
              <span className="bi">a</span>
              <span className="bi">l</span>
              <span className="bi">k</span>
              {/* <span className="bi">C</span>
              <spam className="bi">o</spam>
              <span className="bi">n</span>
              <span className="bi">t</span>
              <span className="bi">a</span>
              <spam className="bi">c</spam>
              <span className="bi">t</span>
              <span className="bi">&nbsp;</span>
              <span className="bi">M</span>
              <span className="bi">e</span> */}
              <span className="text-4 ms-2"></span>
            </Link>
          </div>
          <div className="col-lg-5 text-center align-self-center mb-4 mb-lg-0 order-0 order-lg-1">
            <div className="bg-light rounded-pill d-inline-block p-0 shadow-lg wow zoomIn m-5 photo-parent">
              <div className="card">
                <div className="photo-child">
                  <div className="design1">
                    <span className="image-letter">B</span>
                    <span className="image-letter">r</span>
                    <span className="image-letter">i</span>
                    <span className="image-letter">n</span>
                    <span className="image-letter">g</span>
                  </div>
                  <div className="design2 ">
                    <span className="image-letter">i</span>
                    <span className="image-letter">d</span>
                    <span className="image-letter">e</span>
                    <span className="image-letter">a</span>
                    <span className="image-letter">s</span>
                  </div>
                  <div className="design3">
                    <span className="image-letter">t</span>
                    <span className="image-letter">o</span>
                  </div>
                  <div className="design4">
                    <span className="image-letter">l</span>
                    <span className="image-letter">i</span>
                    <span className="image-letter">f</span>
                    <span className="image-letter">e</span>
                  </div>
                  <div className="design5">
                    <span className="image-letter">w</span>
                    <span className="image-letter">i</span>
                    <span className="image-letter">t</span>
                    <span className="image-letter">h</span>
                  </div>
                  <div className="design6">
                    <span className="image-letter">c</span>
                    <span className="image-letter">o</span>
                    <span className="image-letter">d</span>
                    <span className="image-letter">e</span>
                  </div>
                </div>

                <img
                  className="img-fluid rounded-pill d-block image"
                  src="images/web-developer-char.webp"
                  title="I'm Robert"
                  alt="I'm Robert"
                />
                <img
                  className="img-fluid rounded-pill back-img"
                  src="images/web-developer.webp"
                  title="I'm Robert"
                  alt="I'm Robert"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Link
        className="scroll-down-arrow text-dark smooth-scroll"
        smooth="easeInOutQuint"
        duration={scrollDuration}
        style={{ cursor: "pointer" }}
        to="about"
      >
        <span className="animated">
          <i className="fas fa-chevron-down" />
        </span>
      </Link>
    </section>
  );
};

export default FullScreenDefaultIntro;
