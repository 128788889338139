import React from "react";
import Accordion from "react-bootstrap/Accordion";

const FAQs = () => {
  const faqsData = [
    {
      question: "What is dopecode?",
      answer:
        // "DopeCode is a company founded by me. If you are looking for a front-end developer for your team, if you are interested in working with me in the form of a B2B contract, you will receive invoices for my work for you from DopeCode. If you are a client and I was supposed to do a project for you, so the situation is basically the same. DopeCode will appear! But what does the word “dope” actually mean? In the dictionary, you might find information like this: a dope can be a fool, a slang term for “excellent,” or refer to drugs like marijuana. Both definitions may be true in my situation! You can create code that is great. I hope to make one someday :-) On the other hand, coding is certainly not a drug, but it may by a very good thing and believe me, coding, programming, thinking is really addictive. So I decided to use the word 'dope' in the name of my company. I hope you like it. ",
        "DopeCode is a company founded by me. If you are looking for a front-end developer for your team and are interested in a B2B contract, you will receive invoices for my work from DopeCode. Similarly, if you are a client for whom I am working on a project, the situation remains the same—DopeCode will be the invoicing entity. But what does the word 'dope' actually mean? In the dictionary, you might find various definitions: a 'dope' can be a fool, a slang term for 'excellent,' or refer to drugs like marijuana. Both positive and playful connotations apply here! You can create code that is excellent—something I strive to achieve. On the other hand, while coding is certainly not a drug, it can be highly engaging and addictive. That's why I decided to use the word 'dope' in the name of my company. I hope you like it."
    },
    {
      question: "How can I help You?",
      answer:
        "If you are looking for a front-end developer to join your team, I will be happy to start talks with you about it. If you are a client, I am also ready to talk to create a website for business or personal use. Perhaps I can suggest you a website to develop your ideas. Let me know if you have any question or an offer regarding any of the topics.",
    },
    {
      question: "Where can I work?",
      answer:
        "Anywhere on Planet Earth. Remotely! In hybrid mode: in every city in Poland. Work in the office, 5 days a week: in and around Warsaw",
    },
    {
      question:
        "Why do I choose and usually recommend a responsive single page website?",
      answer:
        "A single page website is one that fits on a single page. Single page websites are fully loaded in the initial page load or page zones are replaced with new page fragments loaded from server on demand, making the experience more continuous and fluid for the user. Single page websites became a trend recently, mostly among designer sites because this type of web design is perfect for a portfolio. But it can also be found in apps or product sites, basically anything that is not heavy on content. Showcasing all the content of a website in a single page is a great way to save time for visitors as they won't have to jump from one page to another and the site only has to load once.",
    },
    {
      question: "What did I do professionally before I started programming?",
      answer:
        "I worked in business, in the world's largest global corporations for many years. Even in such as: Samsung, Canon or Xerox. Successfully. But at some point I was fed up. Really fed up! And I was missing something. I wanted to do something much more creative and constantly develop myself, constantly learning something new and interesting. So I changed my career path and here I am. Fulfilled!",
    },
  ];

  return (
    <section id="faq" className="section bg-light">
      <div className="container">
        <div className="row gy-5">
          <div className="col-lg-6 order-1 order-lg-0 wow fadeInUp">
            {/* Heading */}
            <p className="text-center text-lg-start mb-2">
              <span className="bg-primary text-dark px-4 text-5">FAQ</span>
            </p>
            <h2 className="text-10 fw-600 text-center text-lg-start mb-5">
              Have any questions?
            </h2>
            {/* Heading end*/}

            <Accordion flush defaultActiveKey="0">
              {faqsData.length > 0 &&
                faqsData.map((faq, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{faq.question}</Accordion.Header>
                    <Accordion.Body
                      dangerouslySetInnerHTML={{ __html: faq.answer }}
                    ></Accordion.Body>
                  </Accordion.Item>
                ))}
            </Accordion>
          </div>
          <div
            className="col-lg-6 d-flex align-items-center justify-content-center order-0 order-lg-1 wow fadeIn"
            data-wow-delay="1s"
          >
            {" "}
            <img
              className="img-fluid"
              src="images/faq.png"
              title="FAQ"
              alt="faq"
            />{" "}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
