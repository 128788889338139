import React from "react";

const Services = () => {
  // services details
  const services = [
    {
      name: "App Design & Develop",
      desc: "I create user interfaces by design or create one if not exist that defines what each part of the site does and what it will look like. I make sure that the website is easy to navigate and intuitive for the user and that the website has the required functionality.",
      icon: "fas fa-paint-brush",
    },
    // {
    //   name: "App Design & Develop",
    //   desc: "Creating apps with functional components by web tools and different types of programming languages and frameworks. It is a process that involves a number of steps, including planning, designing, developing, testing, and publishing.",
    //   icon: "fas fa-paint-brush",
    // },
    {
      name: "Web Design",
      desc: "Web design used to be focused on designing websites but now usually refers to the user experience aspects of website development rather than software development. It is a crucial element that I never overlook in my projects.",
      icon: "fas fa-swatchbook",
    },
    {
      name: "Responsive Design",
      desc: "Responsive design is a approach aimed at crafting sites to provide an optimal viewing experience—easy reading and navigation with a minimum of resizing, panning, and scrolling—across a wide range of devices. I always take it into account in my design.",
      icon: "fas fa-mobile-alt",
    },
    // {
    //   name: "Product Customization",
    //   desc: "In the commercial world, product customization is an increasingly desirable requirement and a number of common practices have evolved in response to this customer need. Application development should also leave a unique footprint, specific to a given company or person.",
    //   icon: "fas fa-fingerprint",
    // },
    // {
    //   name: "Bug Fixing", //
    //   desc: " It is required to make sure that the code is working as expected and that you haven’t broken anything.",
    //   icon: "fas fa-bug",
    // },
    // {
    //   name: "Software Testing",
    //   desc: " If you start testing a few days before your app hit the market, you’re too late. A wealth of aspects, data and functionality should be tested from Day One. They help you to ensure that your code is working as expected and that you haven’t broken anything.",
    //   icon: "fas fa-chart-area",
    // },
    // {
    //   name: "SEO",
    //   desc: "SEO is a process of optimizing your website to get organic, or un-paid, traffic from the search engine results page. SEO encompasses both the technical and creative elements required to improve rankings, drive traffic, and increase awareness in search engines.",
    //   icon: "fas fa-bullhorn",
    // },
  ];

  return (
    <section id="services" className="section bg-light">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-4 text-5">What I Do?</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          How can I help You
        </h2>
        {/* Heading end*/}
        <div className="row gy-5 mt-5">
          {services.length > 0 &&
            services.map((service, index) => (
              <div className="col-sm-6 col-lg-4 wow fadeInUp" key={index}>
                <div className="featured-box text-center px-md-4">
                  <div className="featured-box-icon text-primary text-13">
                    {" "}
                    <i className={service.icon} />
                  </div>
                  <h3 className="text-6 fw-600 mb-3">{service.name}</h3>
                  <p className="text-muted mb-0">{service.desc} </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
