import React from "react";
import resumeFile from "../documents/resume.pdf";

const Resume = () => {
  const educationDetails = [
    {
      yearRange: "",
      title: "Psychology (in the course of studies)",
      place: "University of Social Sciences and Humanities",
      desc: "I believe the best way to understand human nature is to study Psychology. This knowledge is useful in every aspect of life, including in professional environments and the creation of front-end applications.",
    },
    {
      yearRange: "",
      title: "Cultural Studies",
      place: "Cardinal Stefan Wyszyński University",
      desc: "Cultural Studies is an important field that allows for exploration of the inner workings of what makes us human and social creatures.",
    },
    {
      yearRange: "",
      title: "Marketing and Management",
      place: "Warsaw School of Economics",
      desc: "Marketing and Management give me marketing knowledge and the ability to understand the mechanisms of the environment in which we live.",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "April 2023 - current",
      title: "Front-End Developer",
      place: "Progso",
      desc: "Tech stack I use: JavaScript, React, React Native, .NET, Blazor, C#, Bootstrap, SCSS, NativeBase. Developing websites and apps for medical and fleet management firms.",
    },
    {
      yearRange: "June 2022 - March 2023",
      title: "Font-End Developer",
      place: "InterSynergy",
      desc: "Tech stack I used: React, React with TS, Next, Bootstrap, SCSS. Creating, implementing new functionalities to large business web application.",
    },
    {
      yearRange: "March 2022 - May 2022",
      title: "Font-End Developer",
      place: "Vectio",
      desc: "Tech stack I used: React.js, Vue.js, ASP.NET Core 6.0, MS SQL Server. Participation in creating new version of the company website.",
    },
  ];

  const skills = [
    {
      name: "JavaScript",
      percent: 80,
    },
    {
      name: "React",
      percent: 80,
    },
    {
      name: "React Native (with Expo)",
      percent: 80,
    },
    {
      name: "Next",
      percent: 60,
    },
    {
      name: "HTML5",
      percent: 80,
    },
    {
      name: "CSS / SCSS",
      percent: 80,
    },
    {
      name: "Bootstrap / Core UI",
      percent: 80,
    },
    {
      name: "Tailwind",
      percent: 60,
    },
  ];

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-4 text-5">Resume</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          A snapshot of my Resume
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Education</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">My Experience</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    <p className="text-muted">{value.desc}</p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* My Skills */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">My Skills</h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href={resumeFile}
            download
          >
            Download CV
          </a>
        </p>
      </div>
    </section>
  );
};

export default Resume;
