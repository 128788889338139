import React from "react";

import Slider from "react-slick";

const Testimonials = () => {
  const reviews = [
    {
      name: "Adriana Baranowska",
      position: "Focus Mind",
      src: "images/testimonial/client-sm-1.jpg",
      desc: "“I am really happy to work with Robert. I am pleased with the projects that he was able to complete for me and I definitely plan to continue to work with him for the long term.”",
    },
    {
      name: "Marcin Cienkusz",
      position: "EMC, Poland",
      src: "images/testimonial/client-sm-2.jpg",
      desc: " “Under high expectations, it was a pleasure to partner with Robert. Professional, communicative, conscientious and fully competent - I wouldn't hesitate in recommending him to other advertising agencies. I am very pleased with my company's homepage created by Robert. ”",
    },
    {
      name: "Ernest",
      position: "User form Poland",
      src: "images/testimonial/client-sm-3.jpg",
      desc: "“A wonderful educational app. I learned a lot of interesting things about the planet Earth and the countries on it. Very helpful. I highly recommend.”",
    },
    // {
    //   name: "Chris Tom",
    //   position: "User from UK",
    //   src: "images/testimonial/client-sm-4.jpg",
    //   desc: "“I have used them twice now. Good rates, very efficient service and priced simply dummy text of the printing and typesetting industry quidam interesset his et. simply dummy text of the printing and typesetting industry. Excellent.”",
    // },
  ];

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-left"></i>
    </button>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <i className="fa fa-chevron-right"></i>
    </button>
  );

  var settings = {
    dots: true,
    arrows: true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
  };

  return (
    <section id="testimonial" className="section bg-secondary">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeIn">
          <span className="bg-primary text-dark px-4 text-5">Client Speak</span>
        </p>
        <h2 className="text-10 fw-600 text-white text-center mb-5 wow fadeIn">
          My Work seen by Others
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-9 mx-auto wow fadeInUp">
            <Slider {...settings}>
              {reviews.length > 0 &&  
                reviews.map((review, index) => (
                  <div className="item text-center px-5" key={index}>
                    {" "}
                    <span className="text-8 text-primary">
                      <i className="fa fa-quote-left" />
                    </span>
                    <p className="text-5 text-white">{review.desc}</p>
                    <img
                      style={{ filter: "blur(2px)" }}
                      className="img-fluid d-inline-block w-auto rounded-circle shadow"
                      src={review.src}
                      alt={review.name}
                    />{" "}
                    <strong className="d-block text-3 fw-600 text-white">
                      {review.name}
                    </strong>{" "}
                    <span className="text-light">{review.position}</span>{" "}
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
